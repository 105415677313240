export const TOKEN_SYMBOL_BFC = 'BFC';
export const TOKEN_SYMBOL_ETH = 'ETH';
export const TOKEN_SYMBOL_ARB = 'ARB';
export const TOKEN_SYMBOL_MATIC = 'MATIC';
export const TOKEN_SYMBOL_BNB = 'BNB';
export const TOKEN_SYMBOL_CORE = 'CORE';
export const TOKEN_SYMBOL_BIFI = 'BiFi';
export const TOKEN_SYMBOL_WBTC = 'WBTC';
export const TOKEN_SYMBOL_BTCB = 'BTCB';
export const TOKEN_SYMBOL_CBBTC = 'cbBTC';
export const TOKEN_SYMBOL_BTCUSD = 'BtcUSD';
export const TOKEN_SYMBOL_USDC = 'USDC';
export const TOKEN_SYMBOL_USDT = 'USDT';
export const TOKEN_SYMBOL_EVERDEX_STAKE_LP = 'EverStableLPToken';

const TOKEN_SYMBOL_TOKENS = [
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_ETH,
  TOKEN_SYMBOL_ARB,
  TOKEN_SYMBOL_MATIC,
  TOKEN_SYMBOL_BNB,
  TOKEN_SYMBOL_CORE,
  TOKEN_SYMBOL_BIFI,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_CBBTC,
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_EVERDEX_STAKE_LP,
];

export default TOKEN_SYMBOL_TOKENS;
