import { Chain } from '@bifrost-platform/bifront-sdk-react-biholder';
import DEFAULT_BLOCK_TERM from '@/configs/defaultValue/defaultBlock';
import {
  MAINNET_CHAIN_API_ID_BIFROST,
  MAINNET_CHAIN_API_ID_ETHEREUM,
  MAINNET_CHAIN_API_ID_BSC,
  MAINNET_CHAIN_API_ID_POLYGON,
  MAINNET_CHAIN_API_ID_ARBITRUM,
  MAINNET_CHAIN_API_ID_BASE,
  MAINNET_CHAIN_API_ID_CORE,
} from './chainApiId';
import {
  MAINNET_CHAIN_EXPLORER_URL_BIFROST,
  MAINNET_CHAIN_EXPLORER_URL_ETHEREUM,
  MAINNET_CHAIN_EXPLORER_URL_BSC,
  MAINNET_CHAIN_EXPLORER_URL_POLYGON,
  MAINNET_CHAIN_EXPLORER_URL_ARBITRUM,
  MAINNET_CHAIN_EXPLORER_URL_BASE,
  MAINNET_CHAIN_EXPLORER_URL_CORE,
} from './chainExplorerUrl';
import {
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_ETHEREUM,
  MAINNET_CHAIN_ID_BSC,
  MAINNET_CHAIN_ID_POLYGON,
  MAINNET_CHAIN_ID_ARBITRUM,
  MAINNET_CHAIN_ID_BASE,
  MAINNET_CHAIN_ID_CORE,
} from './chainId';
import {
  MAINNET_CHAIN_NAME_BIFROST,
  MAINNET_CHAIN_NAME_ETHEREUM,
  MAINNET_CHAIN_NAME_BSC,
  MAINNET_CHAIN_NAME_POLYGON,
  MAINNET_CHAIN_NAME_ARBITRUM,
  MAINNET_CHAIN_NAME_BASE,
  MAINNET_CHAIN_NAME_CORE,
} from './chainName';
import {
  MAINNET_CHAIN_RPC_URLS_BIFROST,
  MAINNET_CHAIN_RPC_URLS_ETHEREUM,
  MAINNET_CHAIN_RPC_URLS_BSC,
  MAINNET_CHAIN_RPC_URLS_POLYGON,
  MAINNET_CHAIN_RPC_URLS_ARBITRUM,
  MAINNET_CHAIN_RPC_URLS_BASE,
  MAINNET_CHAIN_RPC_URLS_CORE,
} from './chainRpcUrls';
import {
  MAINNET_TOKEN_ARBITRUM_ARB,
  MAINNET_TOKEN_BIFROST_BFC,
  MAINNET_TOKEN_BSC_BNB,
  MAINNET_TOKEN_CORE_CORE,
  MAINNET_TOKEN_ETHEREUM_ETH,
  MAINNET_TOKEN_POLYGON_MATIC,
} from '../../tokens/mainnetTokens';

// chain
export const MAINNET_CHAIN_BIFROST: Chain = {
  apiId: MAINNET_CHAIN_API_ID_BIFROST,
  id: MAINNET_CHAIN_ID_BIFROST,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_BIFROST,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_BIFROST,
  name: MAINNET_CHAIN_NAME_BIFROST,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 1,
  coin: MAINNET_TOKEN_BIFROST_BFC,
};
export const MAINNET_CHAIN_ETHEREUM: Chain = {
  apiId: MAINNET_CHAIN_API_ID_ETHEREUM,
  id: MAINNET_CHAIN_ID_ETHEREUM,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_ETHEREUM,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_ETHEREUM,
  name: MAINNET_CHAIN_NAME_ETHEREUM,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: MAINNET_TOKEN_ETHEREUM_ETH,
};
export const MAINNET_CHAIN_ARBITRUM: Chain = {
  apiId: MAINNET_CHAIN_API_ID_ARBITRUM,
  id: MAINNET_CHAIN_ID_ARBITRUM,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_ARBITRUM,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_ARBITRUM,
  name: MAINNET_CHAIN_NAME_ARBITRUM,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: MAINNET_TOKEN_ARBITRUM_ARB,
};
export const MAINNET_CHAIN_POLYGON: Chain = {
  apiId: MAINNET_CHAIN_API_ID_POLYGON,
  id: MAINNET_CHAIN_ID_POLYGON,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_POLYGON,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_POLYGON,
  name: MAINNET_CHAIN_NAME_POLYGON,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.1,
  coin: MAINNET_TOKEN_POLYGON_MATIC,
};
export const MAINNET_CHAIN_BSC: Chain = {
  apiId: MAINNET_CHAIN_API_ID_BSC,
  id: MAINNET_CHAIN_ID_BSC,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_BSC,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_BSC,
  name: MAINNET_CHAIN_NAME_BSC,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.01,
  coin: MAINNET_TOKEN_BSC_BNB,
};
export const MAINNET_CHAIN_BASE: Chain = {
  apiId: MAINNET_CHAIN_API_ID_BASE,
  id: MAINNET_CHAIN_ID_BASE,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_BASE,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_BASE,
  name: MAINNET_CHAIN_NAME_BASE,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.01,
};
export const MAINNET_CHAIN_CORE: Chain = {
  apiId: MAINNET_CHAIN_API_ID_CORE,
  id: MAINNET_CHAIN_ID_CORE,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_CORE,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_CORE,
  name: MAINNET_CHAIN_NAME_CORE,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.01,
  coin: MAINNET_TOKEN_CORE_CORE,
};

export const MAINNET_CHAINS = [
  MAINNET_CHAIN_BIFROST,
  MAINNET_CHAIN_ETHEREUM,
  MAINNET_CHAIN_ARBITRUM,
  MAINNET_CHAIN_POLYGON,
  MAINNET_CHAIN_BSC,
  MAINNET_CHAIN_BASE,
  MAINNET_CHAIN_CORE,
];
