import {
  CoinbaseOption,
  WalletconnectOption,
  WalletConstructorProps,
  WalletOptions,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { EVERY_CHAINS, RPC_MAP } from './chains';
import {
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_ETHEREUM,
} from './chains/mainnet/chainId';
import { MAINNET_CHAIN_RPC_URL_ETHEREUM } from './chains/mainnet/chainRpcUrls';

export const COINBASE_OPTION: CoinbaseOption = {
  appName: 'btcfi',
  defaultChainId: MAINNET_CHAIN_ID_ETHEREUM,
  defaultRpc: MAINNET_CHAIN_RPC_URL_ETHEREUM,
};
export const WALLETCONNECT_OPTION: WalletconnectOption = {
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ?? '',
  chains: [MAINNET_CHAIN_ID_BIFROST],
  optionalChains: [
    1, // ETHEREUM
    56, // BSC
    137, // POLYGON
    8453, // BASE
    42161, // ARBITRUM
    11155111, // ETHEREUM_SEPOLIA testnet
    84532, // BASE_SEPOLIA testnet
    421614, // ARBITRUM_SEPOLIA testnet
    97, // BSC testnet
    80001, // POLYGON testnet
    3068, // BIFROST
    49088, // BIFROST testnet
    ...EVERY_CHAINS.map((chain) => chain.id),
  ],
  methods: [
    'eth_sendTransaction',
    'eth_signTransaction',
    'eth_sign',
    'personal_sign',
    'eth_signTypedData',
  ],
  events: ['chainChanged', 'accountsChanged'],
  rpcMap: RPC_MAP,
  showQrModal: true,
};

export const WALLET_PROVIDER_OPTIONS: WalletOptions = {
  coinbaseOption: COINBASE_OPTION,
  walletconnectOption: WALLETCONNECT_OPTION,
};

const WALLET_CONSTRUCTOR_PROPS: WalletConstructorProps & {
  providerCheckTerm?: number;
} = {
  options: WALLET_PROVIDER_OPTIONS,
  providerCheckTerm: 30000,
};

export default WALLET_CONSTRUCTOR_PROPS;
