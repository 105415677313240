// rpc urls
export const MAINNET_CHAIN_RPC_URLS_BIFROST = [
  'https://public-01.mainnet.thebifrost.io/rpc',
  'https://public-02.mainnet.thebifrost.io/rpc',
];
export const MAINNET_CHAIN_RPC_URLS_ETHEREUM = [
  'https://eth.llamarpc.com',
  'https://rpc.ankr.com/eth',
  'https://ethereum.publicnode.com',
];
export const MAINNET_CHAIN_RPC_URLS_ARBITRUM = [
  'https://arb1.arbitrum.io/rpc',
  'https://arbitrum.llamarpc.com',
  'wss://arbitrum-one.publicnode.com',
];
export const MAINNET_CHAIN_RPC_URLS_POLYGON = [
  'https://rpc.ankr.com/polygon',
  'https://rpc-mainnet.matic.quiknode.pro',
];
export const MAINNET_CHAIN_RPC_URLS_BSC = [
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed4.ninicoin.io',
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed1.defibit.io',
];
export const MAINNET_CHAIN_RPC_URLS_BASE = [
  'https://mainnet.base.org',
  'https://developer-access-mainnet.base.org',
  'https://base.meowrpc.com',
  'https://1rpc.io/base',
];
export const MAINNET_CHAIN_RPC_URLS_CORE = [
  'https://core.public.infstones.com',
  'https://1rpc.io/core',
  'https://core.drpc.org',
];

export const MAINNET_CHAIN_RPC_URL_BIFROST = MAINNET_CHAIN_RPC_URLS_BIFROST[0];
export const MAINNET_CHAIN_RPC_URL_ETHEREUM =
  MAINNET_CHAIN_RPC_URLS_ETHEREUM[0];
export const MAINNET_CHAIN_RPC_URL_ARBITRUM =
  MAINNET_CHAIN_RPC_URLS_ARBITRUM[0];
export const MAINNET_CHAIN_RPC_URL_POLYGON = MAINNET_CHAIN_RPC_URLS_POLYGON[0];
export const MAINNET_CHAIN_RPC_URL_BSC = MAINNET_CHAIN_RPC_URLS_BSC[0];
export const MAINNET_CHAIN_RPC_URL_BASE = MAINNET_CHAIN_RPC_URLS_BASE[0];

export const MAINNET_CHAIN_RPC_URLS = [
  MAINNET_CHAIN_RPC_URLS_BIFROST,
  MAINNET_CHAIN_RPC_URLS_ETHEREUM,
  MAINNET_CHAIN_RPC_URLS_ARBITRUM,
  MAINNET_CHAIN_RPC_URLS_BSC,
  MAINNET_CHAIN_RPC_URLS_POLYGON,
  MAINNET_CHAIN_RPC_URLS_BASE,
  MAINNET_CHAIN_RPC_URLS_CORE,
];
