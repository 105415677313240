import {
  Token,
  ZERO_ADDRESS,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  MAINNET_CHAIN_ID_ARBITRUM,
  MAINNET_CHAIN_ID_BASE,
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_BSC,
  MAINNET_CHAIN_ID_CORE,
  MAINNET_CHAIN_ID_ETHEREUM,
  MAINNET_CHAIN_ID_POLYGON,
} from '@/configs/chains/mainnet/chainId';
import {
  TOKEN_NAME_BFC,
  TOKEN_NAME_BIFI,
  TOKEN_NAME_ETH,
  TOKEN_NAME_BNB,
  TOKEN_NAME_MATIC,
  TOKEN_NAME_ARB,
  TOKEN_NAME_WBTC,
  TOKEN_NAME_BTCB,
  TOKEN_NAME_CBBTC,
  TOKEN_NAME_BTCUSD,
  TOKEN_NAME_USDC,
  TOKEN_NAME_USDT,
  TOKEN_NAME_EVERDEX_STAKE_LP,
  TOKEN_NAME_CORE,
} from './tokenNames';
import {
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_BIFI,
  TOKEN_SYMBOL_ETH,
  TOKEN_SYMBOL_BNB,
  TOKEN_SYMBOL_MATIC,
  TOKEN_SYMBOL_ARB,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_CBBTC,
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_EVERDEX_STAKE_LP,
  TOKEN_SYMBOL_CORE,
} from './tokenSymbols';

// native coin
export const MAINNET_TOKEN_ADDRESS_BIFROST_BFC = ZERO_ADDRESS;
export const MAINNET_TOKEN_ADDRESS_ETHEREUM_ETH = ZERO_ADDRESS;
export const MAINNET_TOKEN_ADDRESS_ARBITRUM_ARB = ZERO_ADDRESS;
export const MAINNET_TOKEN_ADDRESS_POLYGON_MATIC = ZERO_ADDRESS;
export const MAINNET_TOKEN_ADDRESS_BSC_BNB = ZERO_ADDRESS;
export const MAINNET_TOKEN_ADDRESS_CORE_CORE = ZERO_ADDRESS;

// bifi
export const MAINNET_TOKEN_ADDRESS_BIFROST_BIFI =
  '0x047938C3aD13c1eB821C8e310B2B6F889b6d0003';

// wbtc
export const MAINNET_TOKEN_ADDRESS_BIFROST_WBTC =
  '0x7b8fac5f29e101baab33c5f9c39d4f85ba2cc7c1';
export const MAINNET_TOKEN_ADDRESS_ETHEREUM_WBTC =
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599';
export const MAINNET_TOKEN_ADDRESS_ARBITRUM_WBTC =
  '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f';
export const MAINNET_TOKEN_ADDRESS_POLYGON_WBTC =
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6';
export const MAINNET_TOKEN_ADDRESS_CORE_WBTC =
  '0x5832f53d147b3d6cd4578b9cbd62425c7ea9d0bd';

// btcb
export const MAINNET_TOKEN_ADDRESS_BIFROST_BTCB =
  '0xd267f821f1b8344b5a63626c8c824697194a173e';
export const MAINNET_TOKEN_ADDRESS_BSC_BTCB =
  '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c';
export const MAINNET_TOKEN_ADDRESS_CORE_BTCB =
  '0x7a6888c85edba8e38f6c7e0485212da602761c08';

// cbbtc
export const MAINNET_TOKEN_ADDRESS_BIFROST_CBBTC =
  '0x74B73Fd2eE237e9219dF30dfFDB206D237cbFC00';
export const MAINNET_TOKEN_ADDRESS_BASE_CBBTC =
  '0xcbb7c0000ab88b473b1f5afd9ef808440eed33bf';

// btcusd
export const MAINNET_TOKEN_ADDRESS_BIFROST_BTCUSD =
  '0x6906Ccda405926FC3f04240187dd4fAd5DF6d555';

// usdc
export const MAINNET_TOKEN_ADDRESS_BIFROST_USDC =
  '0x640952E7984f2ECedeAd8Fd97aA618Ab1210A21C';

// usdt
export const MAINNET_TOKEN_ADDRESS_BIFROST_USDT =
  '0x3eA8654d5755e673599473ab37d92788B5bA12aE';

// everdex stake lp
export const MAINNET_TOKEN_ADDRESS_BIFROST_EVERDEX_STAKE_LP =
  '0x4E4156F7Aa6970d96C434607aDC7AF6299751Efc';

export const MAINNET_TOKEN_BIFROST_BFC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_BFC,
  name: TOKEN_NAME_BFC,
  symbol: TOKEN_SYMBOL_BFC,
  decimals: 18,
  isCoin: true,
};
export const MAINNET_TOKEN_ETHEREUM_ETH: Token = {
  chainId: MAINNET_CHAIN_ID_ETHEREUM,
  address: MAINNET_TOKEN_ADDRESS_ETHEREUM_ETH,
  name: TOKEN_NAME_ETH,
  symbol: TOKEN_SYMBOL_ETH,
  decimals: 18,
  isCoin: true,
};
export const MAINNET_TOKEN_ARBITRUM_ARB: Token = {
  chainId: MAINNET_CHAIN_ID_ARBITRUM,
  address: MAINNET_TOKEN_ADDRESS_ARBITRUM_ARB,
  name: TOKEN_NAME_ARB,
  symbol: TOKEN_SYMBOL_ARB,
  decimals: 18,
  isCoin: true,
};
export const MAINNET_TOKEN_POLYGON_MATIC: Token = {
  chainId: MAINNET_CHAIN_ID_POLYGON,
  address: MAINNET_TOKEN_ADDRESS_POLYGON_MATIC,
  name: TOKEN_NAME_MATIC,
  symbol: TOKEN_SYMBOL_MATIC,
  decimals: 18,
  isCoin: true,
};
export const MAINNET_TOKEN_BSC_BNB: Token = {
  chainId: MAINNET_CHAIN_ID_BSC,
  address: MAINNET_TOKEN_ADDRESS_BSC_BNB,
  name: TOKEN_NAME_BNB,
  symbol: TOKEN_SYMBOL_BNB,
  decimals: 18,
  isCoin: true,
};
export const MAINNET_TOKEN_CORE_CORE: Token = {
  chainId: MAINNET_CHAIN_ID_CORE,
  address: MAINNET_TOKEN_ADDRESS_CORE_CORE,
  name: TOKEN_NAME_CORE,
  symbol: TOKEN_SYMBOL_CORE,
  decimals: 18,
  isCoin: true,
};
export const MAINNET_TOKEN_BIFROST_BIFI: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_BIFI,
  name: TOKEN_NAME_BIFI,
  symbol: TOKEN_SYMBOL_BIFI,
  decimals: 18,
};
export const MAINNET_TOKEN_BIFROST_WBTC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_ETHEREUM_WBTC: Token = {
  chainId: MAINNET_CHAIN_ID_ETHEREUM,
  address: MAINNET_TOKEN_ADDRESS_ETHEREUM_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_ARBITRUM_WBTC: Token = {
  chainId: MAINNET_CHAIN_ID_ARBITRUM,
  address: MAINNET_TOKEN_ADDRESS_ARBITRUM_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_POLYGON_WBTC: Token = {
  chainId: MAINNET_CHAIN_ID_POLYGON,
  address: MAINNET_TOKEN_ADDRESS_POLYGON_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_CORE_WBTC: Token = {
  chainId: MAINNET_CHAIN_ID_CORE,
  address: MAINNET_TOKEN_ADDRESS_CORE_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_BIFROST_BTCB: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
};
export const MAINNET_TOKEN_BSC_BTCB: Token = {
  chainId: MAINNET_CHAIN_ID_BSC,
  address: MAINNET_TOKEN_ADDRESS_BSC_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
};
export const MAINNET_TOKEN_CORE_BTCB: Token = {
  chainId: MAINNET_CHAIN_ID_CORE,
  address: MAINNET_TOKEN_ADDRESS_CORE_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
};
export const MAINNET_TOKEN_BIFROST_CBBTC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_CBBTC,
  name: TOKEN_NAME_CBBTC,
  symbol: TOKEN_SYMBOL_CBBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_BASE_CBBTC: Token = {
  chainId: MAINNET_CHAIN_ID_BASE,
  address: MAINNET_TOKEN_ADDRESS_BASE_CBBTC,
  name: TOKEN_NAME_CBBTC,
  symbol: TOKEN_SYMBOL_CBBTC,
  decimals: 8,
};
export const MAINNET_TOKEN_BIFROST_BTCUSD: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_BTCUSD,
  name: TOKEN_NAME_BTCUSD,
  symbol: TOKEN_SYMBOL_BTCUSD,
  decimals: 18,
};
export const MAINNET_TOKEN_BIFROST_USDC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_USDC,
  name: TOKEN_NAME_USDC,
  symbol: TOKEN_SYMBOL_USDC,
  decimals: 6,
};
export const MAINNET_TOKEN_BIFROST_USDT: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_USDT,
  name: TOKEN_NAME_USDT,
  symbol: TOKEN_SYMBOL_USDT,
  decimals: 6,
};
export const MAINNET_TOKEN_BIFROST_EVERDEX_STAKE_LP: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFROST_EVERDEX_STAKE_LP,
  name: TOKEN_NAME_EVERDEX_STAKE_LP,
  symbol: TOKEN_SYMBOL_EVERDEX_STAKE_LP,
  decimals: 18,
};

const MAINNET_TOKENS = [
  MAINNET_TOKEN_BIFROST_BFC,
  MAINNET_TOKEN_ETHEREUM_ETH,
  MAINNET_TOKEN_BSC_BNB,
  MAINNET_TOKEN_POLYGON_MATIC,
  MAINNET_TOKEN_CORE_CORE,
  MAINNET_TOKEN_BIFROST_BIFI,
  MAINNET_TOKEN_BIFROST_WBTC,
  MAINNET_TOKEN_ETHEREUM_WBTC,
  MAINNET_TOKEN_ARBITRUM_WBTC,
  MAINNET_TOKEN_POLYGON_WBTC,
  MAINNET_TOKEN_CORE_WBTC,
  MAINNET_TOKEN_BIFROST_BTCB,
  MAINNET_TOKEN_BSC_BTCB,
  MAINNET_TOKEN_CORE_BTCB,
  MAINNET_TOKEN_BIFROST_CBBTC,
  MAINNET_TOKEN_BASE_CBBTC,
  MAINNET_TOKEN_BIFROST_BTCUSD,
  MAINNET_TOKEN_BIFROST_USDC,
  MAINNET_TOKEN_BIFROST_USDT,
  MAINNET_TOKEN_BIFROST_EVERDEX_STAKE_LP,
];

export default MAINNET_TOKENS;
