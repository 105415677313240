import * as Sentry from '@sentry/nextjs';
import {
  SENTRY_EVENT_CONNECT_WALLET,
  SENTRY_EVENT_WITHDRAW_DONE,
  SENTRY_EVENT_SUPPLY_DONE,
  SENTRY_EVENT_BIFI_CLAIM_REWARDS_CLICK,
  SENTRY_EVENT_BIFI_CLAIM_REWARDS_DONE,
  SENTRY_EVENT_BIFI_WITHDRAW_CLICK,
  SENTRY_EVENT_BIFI_WITHDRAW_DONE,
  SENTRY_EVENT_EVERDEX_CLAIM_REWARDS_CLICK,
  SENTRY_EVENT_EVERDEX_CLAIM_REWARDS_DONE,
  SENTRY_EVENT_EVERDEX_UNSTAKING_CLICK,
  SENTRY_EVENT_EVERDEX_UNSTAKING_DONE,
  SENTRY_EVENT_EVERDEX_REMOVE_LIQUIDITY_CLICK,
  SENTRY_EVENT_EVERDEX_REMOVE_LIQUIDITY_DONE,
  SENTRY_EVENT_MIGRATE_CLICK,
  SENTRY_EVENT_MIGRATE_DONE,
} from '@/configs/sentry';
import SentryEvent from '@/types/SentryEvent';

export const sendSentryError = (error: any) => {
  try {
    Sentry.captureException(error);
  } catch (error) {}
};

export const sendSentryEvent = (
  event: SentryEvent & { [key: string]: any }
) => {
  try {
    const name = event.message ?? 'Unknown event';
    const level = 'log';
    const tags = { ...event, createdAt: new Date().getTime() };
    const fingerprint = [event.type, event.message].filter(
      (value) => !!value
    ) as string[];

    Sentry.startSpan(
      {
        name,
        op: 'EventLog',
      },
      () => {
        Sentry.getCurrentScope()
          .setLevel(level)
          .setTags(tags)
          .setFingerprint(fingerprint);
      }
    );
  } catch (error) {}
};

export const sendSentryEventConnectWallet = (
  isTestnet: boolean,
  { providerType, address }: { providerType: string; address: string }
) => {
  sendSentryEvent({
    ...SENTRY_EVENT_CONNECT_WALLET,
    isTestnet,
    providerType,
    address,
  });
};
export const sendSentryEventWithdrawDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_WITHDRAW_DONE, isTestnet });
};
export const sendSentryEventSupplyDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_SUPPLY_DONE, isTestnet });
};
export const sendSentryEventBifiClaimRewardsClick = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_BIFI_CLAIM_REWARDS_CLICK, isTestnet });
};
export const sendSentryEventBifiClaimRewardsDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_BIFI_CLAIM_REWARDS_DONE, isTestnet });
};
export const sendSentryEventBifiWithdrawClick = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_BIFI_WITHDRAW_CLICK, isTestnet });
};
export const sendSentryEventBifiWithdrawDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_BIFI_WITHDRAW_DONE, isTestnet });
};
export const sendSentryEventEverdexClaimRewardsClick = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_EVERDEX_CLAIM_REWARDS_CLICK, isTestnet });
};
export const sendSentryEventEverdexClaimRewardsDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_EVERDEX_CLAIM_REWARDS_DONE, isTestnet });
};
export const sendSentryEventEverdexUnstakingClick = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_EVERDEX_UNSTAKING_CLICK, isTestnet });
};
export const sendSentryEventEverdexUnstakingDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_EVERDEX_UNSTAKING_DONE, isTestnet });
};
export const sendSentryEventEverdexRemoveLiquidityClick = (
  isTestnet: boolean
) => {
  sendSentryEvent({
    ...SENTRY_EVENT_EVERDEX_REMOVE_LIQUIDITY_CLICK,
    isTestnet,
  });
};
export const sendSentryEventEverdexRemoveLiquidityDone = (
  isTestnet: boolean
) => {
  sendSentryEvent({ ...SENTRY_EVENT_EVERDEX_REMOVE_LIQUIDITY_DONE, isTestnet });
};
export const sendSentryEventMigrateClick = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_MIGRATE_CLICK, isTestnet });
};
export const sendSentryEventMigrateDone = (isTestnet: boolean) => {
  sendSentryEvent({ ...SENTRY_EVENT_MIGRATE_DONE, isTestnet });
};
