import { ChainByAPI } from '@bifrost-platform/bifront-sdk-react-biholder';

// api id
export const MAINNET_CHAIN_API_ID_BIFROST: ChainByAPI | undefined = 'bfc';
export const MAINNET_CHAIN_API_ID_ETHEREUM: ChainByAPI | undefined = 'eth';
export const MAINNET_CHAIN_API_ID_ARBITRUM: ChainByAPI | undefined = 'arbi';
export const MAINNET_CHAIN_API_ID_POLYGON: ChainByAPI | undefined = 'matic';
export const MAINNET_CHAIN_API_ID_BSC: ChainByAPI | undefined = 'bsc';
export const MAINNET_CHAIN_API_ID_BASE: ChainByAPI | undefined = 'base';
//@ts-ignore
export const MAINNET_CHAIN_API_ID_CORE: ChainByAPI | undefined = 'core';

export const MAINNET_CHAIN_API_IDS = [
  MAINNET_CHAIN_API_ID_BIFROST,
  MAINNET_CHAIN_API_ID_ETHEREUM,
  MAINNET_CHAIN_API_ID_ARBITRUM,
  MAINNET_CHAIN_API_ID_POLYGON,
  MAINNET_CHAIN_API_ID_BSC,
  MAINNET_CHAIN_API_ID_BASE,
  MAINNET_CHAIN_API_ID_CORE,
];
