export const TOKEN_NAME_BFC = 'Bifrost';
export const TOKEN_NAME_ETH = 'Ethereum';
export const TOKEN_NAME_ARB = 'Arbitrum';
export const TOKEN_NAME_MATIC = 'Polygon';
export const TOKEN_NAME_BNB = 'BNB';
export const TOKEN_NAME_CORE = 'CORE';
export const TOKEN_NAME_BIFI = 'BiFi';
export const TOKEN_NAME_WBTC = 'WBTC';
export const TOKEN_NAME_BTCB = 'BTCB';
export const TOKEN_NAME_CBBTC = 'cbBTC';
export const TOKEN_NAME_BTCUSD = 'BtcUSD';
export const TOKEN_NAME_USDC = 'USDC';
export const TOKEN_NAME_USDT = 'USDT';
export const TOKEN_NAME_EVERDEX_STAKE_LP = 'LP';

const TOKEN_NAMES = [
  TOKEN_NAME_BFC,
  TOKEN_NAME_ETH,
  TOKEN_NAME_ARB,
  TOKEN_NAME_MATIC,
  TOKEN_NAME_BNB,
  TOKEN_NAME_CORE,
  TOKEN_NAME_BIFI,
  TOKEN_NAME_WBTC,
  TOKEN_NAME_BTCB,
  TOKEN_NAME_CBBTC,
  TOKEN_NAME_BTCUSD,
  TOKEN_NAME_USDC,
  TOKEN_NAME_USDT,
  TOKEN_NAME_EVERDEX_STAKE_LP,
];

export default TOKEN_NAMES;
